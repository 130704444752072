import React, { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ROUTING_CONFIG } from '../constants';
import { Context } from '../store';
import { useBrand, useBrandTopSellingProducts } from '../hooks';
import { sessionCacheStorage } from '../services';
import { InfiniteScrollGrid, ProductsItem, ProductsWidget } from '../components';
import {
  getLocalStoreFrontSlug,
  getStateByZipCode,
  getBrandPageForGoogleAnalytics,
} from '../utils';
import BrandNoImage from '../assets/brand-no-image.png';
import { FacebookSecondIcon, InstagramSecondIcon, WebIcon } from '../components/icons/Icons';

export const BrandPage = () => {
  const { state } = useContext(Context);
  const { brandSlug } = useParams();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const navigate = useNavigate();
  const { topSellingBrandProducts } = useBrandTopSellingProducts(brandSlug);
  const zipCode = state?.zipCode ?? '';
  const stateCode = getStateByZipCode(zipCode);
  const { products, brand, isLoading, isError, fetchListMore, hasMore } = useBrand({
    zipCode,
    stateCode,
    brandSlug,
  });

  const brandName = brand?.name ?? '';

  useEffect(() => {
    if (!isLoading && !isError) {
      getBrandPageForGoogleAnalytics(window, brand?.name, sessionCacheStorage.hasToken);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && !brand) {
      navigate(ROUTING_CONFIG.brands.replace(':storeFrontSlug', storeFrontSlug), { replace: true });
    }
  }, [isLoading, brand]);

  const customTitle = (
    <>
      <div className="flex flex-col items-start md:items-center">
        {brand?.bannerImage?.url && (
          <div className="aspect-[6/1] w-full">
            <img
              src={brand?.bannerImage?.url}
              alt={brandName}
              className="aspect-[6/1] w-full object-cover"
            />
          </div>
        )}
        <div className="mt-6 flex w-full flex-col items-center gap-x-4 text-center md:flex-row md:items-start md:justify-start md:text-left">
          <img
            src={brand?.coverImage?.url || BrandNoImage}
            alt={brandName}
            className="mb-4 h-32 w-32 shadow-md md:mb-0 md:mr-10 md:h-44 md:w-44"
          />
          <div className="flex flex-col justify-center gap-y-4 text-center text-sm leading-relaxed text-gray-700 md:min-h-44 md:text-left md:text-base">
            <h1 className="break-words text-xl font-bold capitalize md:text-3xl">{brandName}</h1>
            {brand?.description && <p dangerouslySetInnerHTML={{ __html: brand?.description }} />}
            {(brand?.websiteUrl || brand?.facebookUrl || brand?.instagramUrl) && (
              <div className="flex items-center justify-center md:justify-start">
                {brand?.websiteUrl && (
                  <a href={brand?.websiteUrl} target="_blank" rel="noreferrer" className="mr-3">
                    <WebIcon />
                  </a>
                )}

                {brand?.facebookUrl && (
                  <a href={brand?.facebookUrl} target="_blank" rel="noreferrer" className="mr-3">
                    <FacebookSecondIcon />
                  </a>
                )}

                {brand?.instagramUrl && (
                  <a href={brand?.instagramUrl} target="_blank" rel="noreferrer">
                    <InstagramSecondIcon />
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {topSellingBrandProducts?.length > 0 && (
        <div className="mt-12">
          <ProductsWidget
            key="topSellingBrandProducts"
            items={topSellingBrandProducts}
            title="Top Selling Products"
          />
        </div>
      )}
    </>
  );

  const subTitle = () => <h2 className="mb-4 pl-2 text-xl font-bold capitalize">All Products</h2>;

  return (
    <InfiniteScrollGrid
      itemComponent={ProductsItem}
      customTitle={customTitle}
      title={brandName}
      subTitle={subTitle()}
      data={products}
      isLoading={isLoading}
      isError={isError}
      fetchListMore={fetchListMore}
      hasMore={hasMore}
    />
  );
};
