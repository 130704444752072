import { gql } from '@apollo/client';

export const ITEM_BY_SLUG = gql`
  query getItemBySlug($itemSlug: String) {
    items(filter: { slugEq: $itemSlug }) {
      nodes {
        brand {
          name
          slug
          uid
        }
        categories {
          name
          slug
          uid
        }
        description
        primaryImage {
          url
          mediumUrl
          thumbUrl
        }
        images {
          url
          mediumUrl
          thumbUrl
        }
        name
        prices {
          currencyCode
          currencySign
          type
          value
        }
        slug
        uid
        sku
        itemGroup {
          uid
        }
        availability {
          availableQty
          availabilityControl
          outOfStockThreshold
          lowInStockThreshold
        }
        videoLinks
        itemOverviews {
          uid
          title
          body
        }
        itemVariants {
          key
          value
        }
        frequentQuestions {
          uid
          question
          answer
        }
      }
    }
  }
`;

export const ITEM_BY_ID = gql`
  query getItemById($filter: ItemsFilterInput) {
    items(filter: $filter) {
      nodes {
        slug
        uid
      }
    }
  }
`;

export const ITEMS_BY_GROUP_ID = gql`
  query getItemByGroupId($groupId: String) {
    items(filter: { itemGroupUidEq: $groupId }) {
      nodes {
        slug
        uid
        slug
        itemVariants {
          key
          value
        }
      }
    }
  }
`;
