import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { getLocalStoreFrontSlug } from '../../utils';

const NavElement = ({ slug, label, isActive }) => {
  const storeFrontSlug = getLocalStoreFrontSlug();
  return (
    <Link
      to={`/${storeFrontSlug}/products/${slug}`}
      className={clsx(
        'flex h-9 w-auto items-center justify-center rounded-md border border-gray-light px-3 font-bold leading-none',
        isActive ? 'bg-secondary text-white' : 'text-gray-dark',
      )}
    >
      {label}
    </Link>
  );
};

export const VariantsNavigation = ({ productsGroup, currentColor, currentSize }) => {
  const colors = useMemo(
    () =>
      productsGroup.filter(
        (product) => product.itemVariants?.size === currentSize && product.itemVariants?.color,
      ),
    [productsGroup, currentSize],
  );

  const sizes = useMemo(
    () =>
      productsGroup.filter(
        (product) => product.itemVariants?.color === currentColor && product.itemVariants?.size,
      ),
    [productsGroup, currentColor],
  );

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex gap-x-4">
        {colors.map((item) => (
          <NavElement
            key={`${item.slug}-${item.itemVariants.color}`}
            slug={item.slug}
            label={item.itemVariants.color}
            isActive={item.itemVariants.color === currentColor}
          />
        ))}
      </div>
      <div className="flex gap-x-4">
        {sizes.map((item) => (
          <NavElement
            key={`${item.slug}-${item.itemVariants.size}`}
            slug={item.slug}
            label={item.itemVariants.size}
            isActive={item.itemVariants.size === currentSize}
          />
        ))}
      </div>
    </div>
  );
};
