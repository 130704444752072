import { useLazyQuery, useQuery } from '@apollo/client';
import { ITEM_BY_ID, ITEM_BY_SLUG, ITEMS_BY_GROUP_ID } from '../queries';
import { clientNameService } from '../services';
import { convertProduct } from '../utils';

export const useProductBySlugQuery = (itemSlug) => {
  const {
    data: { items: { nodes = [] } = {} } = {},
    loading,
    error,
  } = useQuery(ITEM_BY_SLUG, {
    variables: { itemSlug },
    context: {
      clientName: clientNameService.clientName,
    },
  });

  if (error) {
    console.error(error);
  }

  return {
    product: nodes.length ? convertProduct(nodes[0]) : {},
    isLoading: loading,
    isError: error,
  };
};

export const useProductByIdLazyQuery = () => {
  const [fetchProduct, { data, loading, error }] = useLazyQuery(ITEM_BY_ID, {
    context: {
      clientName: clientNameService.clientName,
    },
  });

  return {
    fetchProduct,
    product: data?.items?.nodes?.[0] || null,
    loading,
    error,
  };
};

export const useProductsByGroupIdQuery = (groupId) => {
  console.log('groupId', groupId);
  const {
    data: { items: { nodes = [] } = {} } = {},
    loading,
    error,
  } = useQuery(ITEMS_BY_GROUP_ID, {
    variables: { groupId },
    context: {
      clientName: clientNameService.clientName,
    },
  });

  if (error) {
    console.error(error);
  }

  return {
    productsGroup: nodes.length ? nodes.map((product) => convertProduct(product)) : [],
    isLoading: loading,
    isError: error,
  };
};
