import React, { useRef } from 'react';
import clsx from 'clsx';
import { BUTTON_COLORS_TYPES, BUTTON_SIZES_TYPES } from '../../constants';

const colorStyles = {
  [BUTTON_COLORS_TYPES.primary]:
    'text-white bg-primary hover:bg-white hover:text-primary hover:shadow-rounded disabled:hover:shadow-lg shadow-lg',
  [BUTTON_COLORS_TYPES.default]: 'text-gray-700 bg-gray-200 hover:bg-gray-300',
  [BUTTON_COLORS_TYPES.white]: 'text-gray-700 bg-white hover:bg-gray-100',
  [BUTTON_COLORS_TYPES.blue]: 'text-gray-700 bg-blue-500 hover:bg-gray-100 hover:text-black',
};

const styleBySize = {
  [BUTTON_SIZES_TYPES.medium]: 'max-w-[250px]',
  [BUTTON_SIZES_TYPES.large]: 'max-w-[300px]',
  [BUTTON_SIZES_TYPES.action]: 'max-w-[170px]',
};

export const Button = ({
  children,
  className,
  size = BUTTON_SIZES_TYPES.medium,
  color = BUTTON_COLORS_TYPES.primary,
  startIcon,
  id,
  ...props
}) => {
  const colorClass = colorStyles[color];
  const sizeClass = styleBySize[size];

  const buttonRef = useRef(null);

  const handleRipple = (event) => {
    const button = buttonRef.current;
    const rect = button.getBoundingClientRect();
    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    const rippleX = event.clientX - rect.left - radius;
    const rippleY = event.clientY - rect.top - radius;

    circle.style.width = `${diameter}px`;
    circle.style.height = `${diameter}px`;
    circle.style.left = `${rippleX}px`;
    circle.style.top = `${rippleY}px`;
    circle.classList.add('ripple');
    circle.classList.add('bg-primary');

    const existingRipple = button.getElementsByClassName('ripple')[0];
    if (existingRipple) {
      existingRipple.remove();
    }

    button.appendChild(circle);
  };

  return (
    <button
      id={id}
      ref={buttonRef}
      onClick={handleRipple}
      className={clsx(
        'relative flex h-9 w-full items-center justify-center gap-x-3 overflow-hidden rounded-3xl px-2.5 py-2.5 text-xs font-semibold uppercase tracking-widest shadow-lg hover:shadow-rounded disabled:bg-gray-light disabled:text-gray-400 disabled:hover:shadow-lg',
        colorClass,
        sizeClass,
        className,
      )}
      {...props}
    >
      {startIcon && <span className="mr-2">{startIcon}</span>}
      {children}
    </button>
  );
};
