import { API_HEADERS } from '../constants';
import { sessionCacheStorage } from '../services';

export const getSessionHeader = () => {
  if (sessionStorage.getItem('preview') === 'true') {
    return {};
  }

  return sessionCacheStorage.hasToken
    ? {
        [API_HEADERS.session]: sessionCacheStorage.token,
      }
    : {};
};
