import { gql } from '@apollo/client';

export const PROMOTION_BANNERS_DATA = gql`
  query promotionBanners($filter: PromotionBannersFilterInput, $pagination: PaginationInput) {
    promotionBanners(filter: $filter, pagination: $pagination) {
      nodes {
        uid
        name
        locationType
        position
        clickUrl
        bannerImage {
          signedBlobId
          url
        }
      }
      pagination {
        totalNodes
        totalPages
        perPage
        currentPage
        lastPage
        nextPage
      }
    }
  }
`;
