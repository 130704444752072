import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InputTextField } from '../input-text-field';
import { Button } from '../button';
import { useValidationLocationSchema } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  formTitle: {
    color: theme.palette.gray.dark,
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 'bold',
    margin: '0 0 32px',
  },
  formSubTitle: {
    color: theme.palette.gray.dark,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'normal',
    margin: '0 auto 32px',
    maxWidth: '80%',
  },
  input: {
    WebkitBoxShadow: '0 0 0 1000px white inset !important',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
    '&:disabled': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
  },
  asterisk: {
    color: 'red',
  },
  button: {
    marginTop: '40px',
  },
}));

export const UpdateLocationForm = ({ handleSubmit }) => {
  const classes = useStyles();
  const formSchema = useValidationLocationSchema();
  const {
    control,
    handleSubmit: handleFormSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data) => {
    handleSubmit(data);
  };

  return (
    <form onSubmit={handleFormSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12}>
          <p className={classes.formTitle}>Update your location</p>
        </Grid>

        <Grid item xs={12} align="center">
          <p className={classes.formSubTitle}>
            Let us know your zip code so we can display all the products available in your area
          </p>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="zipCode"
            control={control}
            render={({ field }) => (
              <InputTextField
                {...field}
                fullWidth
                variant="outlined"
                error={touchedFields.zipCode && !!errors.zipCode}
                helperText={touchedFields.zipCode && errors.zipCode?.message}
                label="Zip Code"
                InputLabelProps={{
                  required: true,
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                inputProps={{ className: classes.input }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            size="medium"
            id="submit-location"
            color="primary"
            className={classes.button}
            variant="contained"
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
