import { LOCAL_STORAGE_KEYS } from '../constants';
import { storageService } from './storage-service';

class ZipCodeService {
  set zipCode(value) {
    storageService.setItem(LOCAL_STORAGE_KEYS.zipCode, value);
  }

  get zipCode() {
    return storageService.getItem(LOCAL_STORAGE_KEYS.zipCode);
  }

  remove() {
    storageService.removeItem(LOCAL_STORAGE_KEYS.zipCode);
  }
}

export const zipCodeService = new ZipCodeService();
