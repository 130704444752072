import React, { useContext, useEffect } from 'react';
import { Context } from '../store';
import { useDealsProducts, useGetPromotionBanners } from '../hooks';
import { clientNameService, sessionCacheStorage } from '../services';
import { InfiniteScrollGrid, ProductsItem } from '../components';
import { getDealsPageForGoogleAnalytics, getStateByZipCode } from '../utils';
import { PRICE_TYPES, LOCATION_TYPES } from '../constants';

export const Deals = () => {
  const { state } = useContext(Context);
  const zipCode = state?.zipCode ?? '';
  const stateCode = getStateByZipCode(zipCode);
  const { products, isLoading, isError, fetchListMore, hasMore } = useDealsProducts({
    filter: {
      priceTypeEq: clientNameService?.isWholesaleClient
        ? PRICE_TYPES?.wholesaleSale
        : PRICE_TYPES?.retailSale,
      ...(!clientNameService?.isWholesaleClient && zipCode
        ? {
            uidOmitsItemRestrictionsIn: [zipCode, stateCode?.shortName],
            brandUidOmitsBrandRestrictionsIn: [zipCode, stateCode?.shortName],
          }
        : {}),
    },
  });
  const { promotionBanners } = useGetPromotionBanners({
    filter: {
      locationTypeEq: LOCATION_TYPES?.deals,
    },
  });

  useEffect(() => {
    if (!isLoading && !isError) {
      getDealsPageForGoogleAnalytics(window, sessionCacheStorage.hasToken);
    }
  }, [isLoading]);

  return (
    <>
      <InfiniteScrollGrid
        promotionBanners={promotionBanners}
        itemComponent={ProductsItem}
        title="Deals"
        data={products}
        isLoading={isLoading}
        isError={isError}
        fetchListMore={fetchListMore}
        hasMore={hasMore}
        titleClassName="!text-left !text-gray-dark !text-21 !font-bold !leading-custom !mb-10"
      />
    </>
  );
};
