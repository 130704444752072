export const convertProduct = (product) => {
  const convertItemVariants = (itemVariantsArray) => {
    if (!itemVariantsArray || itemVariantsArray.length === 0) return {};

    const variants = itemVariantsArray.reduce((acc, { key, value }) => {
      acc[key] = value;
      return acc;
    }, {});

    if (!variants.size) variants.size = 'no size';
    if (!variants.color) variants.color = 'no color';

    return variants;
  };

  return {
    ...product,
    itemVariants: convertItemVariants(product.itemVariants),
  };
};
