import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden, Tabs, Tab } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { ACTIONS_TYPES, ITEMS_OVERVIEW, PRODUCT_TAB_DETAILS } from '../../constants';
import { Context } from '../../store';
import { useProductBySlugQuery, useProductsByGroupIdQuery } from '../../hooks';
import { sessionCacheStorage } from '../../services';
import { ProductImages } from './product-images';
import {
  getProductPrices,
  getCategoryName,
  getCategorySlug,
  getAvailableQuantity,
  getAvailabilityControl,
  getStockThresholds,
  getProductPageForGoogleAnalytics,
} from '../../utils';
import {
  Spinner,
  Breadcrumbs,
  EmptyState,
  ErrorState,
  ProductPrices,
  AddToCartButton,
  LabelOfStock,
  ProductOverviewTab,
  ProductVideosTab,
  ProductFaqTab,
  VariantsNavigation,
} from '../../components';

const useStyles = makeStyles((theme) => ({
  info: {
    paddingLeft: '40px',
  },
  title: {
    fontSize: '25px',
    lineHeight: '28px',
    color: 'var(--dark-gray)',
    fontWeight: 700,
    textTransform: 'capitalize',
    margin: 0,
    overflowWrap: 'break-word',
    [theme.breakpoints.up('sm')]: {
      fontSize: '29px',
      lineHeight: '29px',
    },
  },
  brandCategoryContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
  },
  category: {
    fontSize: '10px',
    color: 'var(--gray)',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.6px',
    textTransform: 'uppercase',
    margin: 0,
    marginLeft: '3px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
    },
  },
  brand: {
    fontSize: '10px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.6px',
    textTransform: 'uppercase',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
    },
  },
  price: {
    position: 'relative',
    margin: '14px 0 16px',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    marginLeft: '10px',
    padding: '4px 10px',
    fontSize: '12px',
    lineHeight: '14px',
    borderRadius: '13px',
  },
  cartAmount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  cartBtn: {
    borderRadius: '25px',
    color: 'var(--white)',
    fontSize: '12px',
    fontWeight: 700,
    background: theme.palette.primary.main,
    boxShadow: '0px 3px 15px #0000001A',
    textTransform: 'none',
    height: '40px',
    width: '100%',
    marginTop: '5px',
    [theme.breakpoints.up('sm')]: {
      width: '300px',
      fontSize: '15px',
      height: '50px',
    },
    '& svg': {
      height: '24px',
      width: '24px',
      marginRight: '12px',
    },
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'var(--white)',
      boxShadow: '0px 3px 15px #0000001A',
    },
  },
  qtyLabel: {
    fontWeight: 600,
    color: 'var(--dark-gray)',
    margin: 0,
    marginRight: '15px',
  },
  container: {
    marginBottom: theme.spacing(8),
  },
  tabsContainer: {
    marginTop: '15px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '54px',
    },
  },
  tabs: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    lineHeight: '24px',
  },
}));

export const ProductDetailsPage = () => {
  const classes = useStyles();
  const { productSlug, storeFrontSlug } = useParams();
  const { product, isLoading, isError } = useProductBySlugQuery(productSlug);
  const [currentTab, setCurrentTab] = useState('');
  const { state, dispatch } = useContext(Context);
  const [enabledTabs, setEnabledTabs] = useState([]);
  const { productsGroup } = useProductsByGroupIdQuery(product?.itemGroup?.uid);

  useEffect(() => {
    if (product) {
      const filteredTabs = PRODUCT_TAB_DETAILS.filter((el) => {
        if (el.name === ITEMS_OVERVIEW) {
          return product?.description?.length || product?.[ITEMS_OVERVIEW]?.length;
        }
        return product[el.name]?.length;
      });
      setEnabledTabs(filteredTabs);
      setCurrentTab(filteredTabs[0]?.name);
    }
  }, [product.videoLinks, product.itemOverviews, product.frequentQuestions]);
  const cartIndex = state.cart.findIndex((item) => item.uid === product.uid);

  const addToCart = () => {
    dispatch({ type: ACTIONS_TYPES.ADD_TO_CART, payload: product });
  };

  const incrementAmount = useCallback(() => {
    dispatch({ type: ACTIONS_TYPES.INCREMENT_AMOUNT, payload: product.uid });
  }, [dispatch, product.uid]);

  const decrementAmount = useCallback(() => {
    dispatch({ type: ACTIONS_TYPES.DECREMENT_AMOUNT, payload: product.uid });
  }, [dispatch, product.uid]);

  const maxQuantity = getAvailableQuantity(product);
  const availabilityControl = getAvailabilityControl(product);
  const { inStock, outOfStock } = getStockThresholds(product);
  const isOutOfStock = availabilityControl && outOfStock <= 0;
  const isInStock = availabilityControl && inStock > 0;

  const addToCartButton = (
    <AddToCartButton
      classes={classes}
      data={state?.cart[cartIndex]}
      maxQuantity={maxQuantity}
      incrementAmount={incrementAmount}
      decrementAmount={decrementAmount}
      addToCart={addToCart}
      availabilityControl={availabilityControl}
    />
  );

  const productPrices = getProductPrices(product);
  const categoryName = getCategoryName(product);
  const categorySlug = getCategorySlug(product);
  const productImages = useMemo(() => {
    const images = [];

    if (product.primaryImage) {
      images.push(product.primaryImage);
    }

    if (product.images) {
      images.push(...product.images.filter((image) => image.url !== product.primaryImage?.url));
    }

    return images;
  }, [product?.primaryImage?.url]);

  const productName = product?.name || '';
  const brandName = product?.brand?.name || '';
  const brandSlug = product?.brand?.slug || '';

  const handleChangeTab = (_event, newValue) => setCurrentTab(newValue);

  useEffect(() => {
    if (productName && !isLoading && !isError) {
      getProductPageForGoogleAnalytics(
        window,
        productName,
        categoryName,
        sessionCacheStorage.hasToken,
      );
    }
  }, [isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {isError ? (
        <ErrorState />
      ) : (
        <>
          {!product?.uid ? (
            <EmptyState />
          ) : (
            <>
              <Breadcrumbs
                links={[
                  { link: `/brands/${brandSlug}`, linkName: brandName },
                  { link: `/categories/${categorySlug}`, linkName: categoryName },
                ]}
                catalogName={productName}
              />

              <Grid className={classes.container} container spacing={4}>
                <Grid align="center" item xs={12} sm={6}>
                  <ProductImages images={productImages} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div>
                    <div className={classes.brandCategoryContainer}>
                      <h5 className={classes.brand}>{brandName} | </h5>
                      {product?.categories?.map((category, index) => (
                        <Link key={index} to={`/${storeFrontSlug}/categories/${category.slug}`}>
                          <h5 className={classes.category}>
                            {category.name}{' '}
                            {index !== product.categories.length - 1 && <> &bull;</>}
                          </h5>
                        </Link>
                      ))}
                    </div>
                    <h1 className={classes.title}>{productName}</h1>
                    <div className="mt-2 flex w-auto items-start justify-start">
                      <span className="rounded-md bg-gray-light px-2 py-1 text-xs text-black">
                        SKU: <span className="font-semibold">{product.sku}</span>
                      </span>
                    </div>
                    <div className={classes.price}>
                      <ProductPrices productPrices={productPrices} />

                      <>
                        {isOutOfStock && <LabelOfStock className={classes.label} />}
                        {isInStock && <LabelOfStock inStock={inStock} className={classes.label} />}
                      </>
                    </div>
                    <VariantsNavigation
                      productsGroup={productsGroup}
                      currentSize={product?.itemVariants?.size}
                      currentColor={product?.itemVariants?.color}
                    />

                    {!isOutOfStock && <Hidden smUp>{addToCartButton}</Hidden>}
                  </div>
                  {!isOutOfStock && <Hidden xsDown>{addToCartButton}</Hidden>}
                </Grid>

                <Grid container spacing={4} className={classes.tabsContainer}>
                  <Tabs value={currentTab} onChange={handleChangeTab} className={classes.tabs}>
                    {enabledTabs.map((tab) => (
                      <Tab label={tab.title} key={tab.name} value={tab.name} />
                    ))}
                  </Tabs>
                </Grid>

                <Grid container className={classes.tabsContainer}>
                  {currentTab === PRODUCT_TAB_DETAILS[0].name && (
                    <ProductOverviewTab
                      description={product?.description}
                      overviews={product?.itemOverviews}
                    />
                  )}
                  {currentTab === PRODUCT_TAB_DETAILS[1].name && (
                    <ProductVideosTab videoLinks={product?.videoLinks} />
                  )}
                  {currentTab === PRODUCT_TAB_DETAILS[2].name && (
                    <ProductFaqTab questions={product?.frequentQuestions} />
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};
