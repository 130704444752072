import React, { useState, useMemo, useContext } from 'react';
import { authViewStates } from '../../constants/auth-view-states';
import { SignInForm } from '../sign-in-form';
import { SignUpForm } from '../sign-up-form';
import { MainModal } from '../modal';
import { useCreateAccount, useLoginAccount, useShowToast } from '../../hooks';
import { Spinner } from '../spinner';
import { emailService } from '../../services';
import { SignInResend } from '../sign-in-resend';
import { ChevronRightIcon, ProfileIcon, VectorIcon } from '../icons/Icons';
import { ROUTING_CONFIG } from '../../constants';
import { getLocalStoreFrontSlug } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Popover } from '../app-bar/popover';
import { Context } from '../../store';

export const Auth = ({ onClick }) => {
  const { state } = useContext(Context);
  const { account } = state || {};
  const [currentView, setView] = useState(authViewStates.signIn);
  const [isOpeningAuth, setOpeningAuth] = useState(false);
  const { loading: loadingCreateAccount, createAccount } = useCreateAccount();
  const { loading: loadingLoginAccount, sendLoginLink } = useLoginAccount();
  const [isSFOUserType, setIsSFOUserType] = useState(false);
  const { showSuccess } = useShowToast();
  const navigate = useNavigate();
  const storeFrontSlug = getLocalStoreFrontSlug();

  const accountDetailsLink = useMemo(
    () => ROUTING_CONFIG.accountDetails.replace(':storeFrontSlug', storeFrontSlug),
    [storeFrontSlug],
  );

  const handleAuthClose = () => {
    setOpeningAuth(false);
    setIsSFOUserType(false);
    setView(authViewStates.signIn);
    emailService.remove();
  };

  const handleAuthOpen = () => {
    onClick?.();
    account ? navigate(accountDetailsLink) : setOpeningAuth(true);
  };

  const handleCreateAccount = async ({ name, email }) => {
    const createAccountData = await createAccount({ formalName: name, email });

    if (createAccountData) {
      emailService.email = email;
      showSuccess('Create account has been success');
      setView(authViewStates.signIn);
    }
  };

  const handleLoginAccount = async ({ email }) => {
    const loginAccountData = await sendLoginLink({ email, isSFOUserType });

    if (loginAccountData) {
      showSuccess('Letter has been sent');
      emailService.email = email;
      setView(authViewStates.resend);
    }
  };

  const navigateToUserDetails = () => {
    onClick?.();
    navigate(`/${storeFrontSlug}/account-details/settings`);
  };

  const renderForm = useMemo(() => {
    switch (currentView) {
      case authViewStates.signIn:
        return (
          <SignInForm
            email={emailService.email}
            changeState={setView}
            handleLoginAccount={handleLoginAccount}
            isSFOUserType={isSFOUserType}
            setIsSFOUserType={setIsSFOUserType}
          />
        );
      case authViewStates.signUp:
        return <SignUpForm changeState={setView} handleCreateAccount={handleCreateAccount} />;
      case authViewStates.resend:
        return (
          <SignInResend
            email={emailService.email}
            handleLoginAccount={handleLoginAccount}
            isSFOUserType={isSFOUserType}
          />
        );
      default:
        return (
          <SignInForm
            email={emailService.email}
            changeState={setView}
            handleLoginAccount={handleLoginAccount}
          />
        );
    }
  }, [currentView, handleLoginAccount, handleCreateAccount]);

  return (
    <>
      <div className="hidden lg:block">
        {account ? (
          <Popover account={account} navigateToUserDetails={navigateToUserDetails}>
            <div className="flex cursor-pointer items-center gap-2 font-semibold text-gray-light">
              <ProfileIcon className="h-4 w-4" />
              {account.contact.formalName}
              <VectorIcon className="h-2 w-2" />
            </div>
          </Popover>
        ) : (
          <div
            className="flex cursor-pointer items-center gap-2 font-semibold text-gray-light"
            onClick={handleAuthOpen}
          >
            <ProfileIcon className="h-4 w-4" />
            Sign In
          </div>
        )}
      </div>

      <div className="lg:hidden">
        {account ? (
          <div
            className="flex cursor-pointer items-center justify-between"
            onClick={navigateToUserDetails}
          >
            <div>
              <div id="nav-myaccount" className="font-semibold text-primary">
                {account.contact.formalName}
              </div>
              <div id="nav-myaccount" className="text-xs">
                {account.contact.email}
              </div>
            </div>
            <ChevronRightIcon className="ml-6 h-4 w-6 text-primary" />
          </div>
        ) : (
          <div
            className="flex cursor-pointer flex-col items-center gap-2 font-semibold text-black"
            onClick={handleAuthOpen}
          >
            <ProfileIcon className="h-4 w-4" />
            Sign In
          </div>
        )}
      </div>

      <MainModal isOpen={isOpeningAuth} onClose={handleAuthClose} disablePortal={false}>
        {(loadingCreateAccount || loadingLoginAccount) && <Spinner isBackground />}
        {renderForm}
      </MainModal>
    </>
  );
};
