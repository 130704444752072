import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryString } from '../../hooks';
import { ROUTING_CONFIG } from '../../constants';
import { useProductByIdLazyQuery } from '../../hooks';

export const NavigatorPage = () => {
  const { search } = useLocation();
  const params = useQueryString(search);
  const { storeFrontSlug } = useParams();
  const navigate = useNavigate();
  const { fetchProduct } = useProductByIdLazyQuery();

  useEffect(() => {
    const errorHandler = () => {
      navigate(ROUTING_CONFIG.home.replace(':storeFrontSlug', storeFrontSlug), { replace: true });
    };

    const handlers = {
      productDetails: async () => {
        if (!params?.productId) return errorHandler();

        try {
          const result = await fetchProduct({
            variables: { filter: { uidIn: [params.productId] } },
          });

          if (params.referralId) {
            sessionStorage.setItem('referralId', params.referralId);
          }

          const productSlug = result?.data?.items?.nodes?.[0]?.slug;
          if (productSlug) {
            navigate(
              ROUTING_CONFIG.productDetails
                .replace(':storeFrontSlug', storeFrontSlug)
                .replace(':productSlug', productSlug),
              { replace: true },
            );
          } else {
            errorHandler();
          }
        } catch (error) {
          console.error('Failed to fetch product details:', error);
          errorHandler();
        }
      },
    };

    if (params?.type && handlers[params.type]) {
      handlers[params.type]();
    }
  }, []);

  return null;
};
