import React from 'react';
import { Button } from '../button';
import { BasketIcon } from '../icons/Icons';
import { ProductCartAmount } from '../product-cart-amount';
import clsx from 'clsx';

export const AddToCartButton = ({
  data,
  classes,
  incrementAmount,
  decrementAmount,
  maxQuantity,
  addToCart,
  availabilityControl,
}) => {
  if (availabilityControl && maxQuantity <= 0) {
    return <h3 className={classes.category}>Out of stock</h3>;
  }

  return (
    <div style={{ minHeight: '57px' }}>
      {data ? (
        <>
          <p className={classes.qtyLabel}>IN CART:</p>
          <ProductCartAmount
            value={data?.amount}
            increment={incrementAmount}
            decrement={decrementAmount}
            maxQuantity={maxQuantity}
            availabilityControl={availabilityControl}
          />
        </>
      ) : (
        <div className="flex justify-center md:justify-start">
          <Button
            size="large"
            className={clsx('add-to-cart', 'mt-2')}
            startIcon={<BasketIcon className="" />}
            onClick={addToCart}
          >
            Add to cart
          </Button>
        </div>
      )}
    </div>
  );
};
