import { slugService, storageService } from '../services';
import { LOCAL_STORAGE_KEYS } from '../constants';

export const setLocalStorageBasket = (data) => {
  const slugName = slugService.slug;

  storageService.setItem(LOCAL_STORAGE_KEYS.basket + slugName, JSON.stringify(data));
};

export const getLocalStorageBasket = () => {
  const slugName = slugService.slug;

  const cart = storageService.getItem(LOCAL_STORAGE_KEYS.basket + slugName);

  return cart ? JSON.parse(cart) : null;
};
