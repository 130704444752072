import React, { useContext } from 'react';
import { useCreateSubscriptionAuthLink, useGetSchedulerConfig, useShowToast } from '../../hooks';
import { NavLink } from 'react-router-dom';
import { BasketHeaderIcon, BookIcon } from '../icons/Icons';
import { getNormalizeDate } from '../../utils/get-normalize-date';
import { getLocalStoreFrontSlug } from '../../utils';
import { Context } from '../../store';
import { clientNameService } from '../../services';
import { CLIENT_NAME } from '../../constants';
import { ShareButton } from '../share-button';

export const IconsBlock = ({ onClick, isMenuOpen }) => {
  const { state } = useContext(Context);
  const cartAmount = state.cart.reduce((acc, item) => acc + item.amount, 0);
  const { account } = state || {};

  const currentDate = getNormalizeDate(new Date());
  const { data: schedulerConfig } = useGetSchedulerConfig();
  const handleCreateSubscriptionAuthLink = useCreateSubscriptionAuthLink();
  const { showError } = useShowToast();
  const storeFrontSlug = getLocalStoreFrontSlug();

  const prepareSchedulerLink = async () => {
    const payload = {
      landingUrl: `${window.location.origin.replace('.', '.scheduler.')}/${
        window.location.pathname.split('/')[1]
      }`,
    };

    if (!account) return payload.landingUrl;

    const response = await handleCreateSubscriptionAuthLink(payload);
    return response?.data?.createSchedulerAuthLink?.url || payload.landingUrl;
  };

  const routeSchedulerLink = async () => {
    isMenuOpen && onClick?.();
    try {
      const schedulerLink = await prepareSchedulerLink();
      if (schedulerLink) {
        window.location.href = schedulerLink;
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <div className="col-span-3 flex items-center justify-center md:justify-end">
      {clientNameService.clientName === CLIENT_NAME.wholesale && <ShareButton />}

      {schedulerConfig?.schedulerConfig?.subscriptionExpiryDate !== null &&
        getNormalizeDate(schedulerConfig?.schedulerConfig?.subscriptionExpiryDate) >=
          currentDate && (
          <button onClick={routeSchedulerLink} className="p-1">
            <BookIcon />
          </button>
        )}

      {cartAmount ? (
        <NavLink
          id="nav-cart" // ID needed for Google Analytics
          to={`/${storeFrontSlug}/cart`}
          className="pr-4 md:pr-1"
          onClick={() => isMenuOpen && onClick?.()}
        >
          <button className="relative p-1">
            <span className="absolute right-0.5 top-0.5 flex h-4 w-4 items-center justify-center rounded-full border border-white bg-primary text-xs font-bold text-white">
              {cartAmount}
            </span>
            <BasketHeaderIcon />
          </button>
        </NavLink>
      ) : (
        <button id="nav-cart" className="relative p-1 pr-5 md:pr-2">
          <BasketHeaderIcon />
        </button>
      )}
    </div>
  );
};
