import React, { useContext } from 'react';
import { Slider, Slide, CarouselContext } from 'pure-react-carousel';

export const SlidesWidget = ({ items }) => {
  const carouselContext = useContext(CarouselContext);

  const start = () => {
    carouselContext.setStoreState({ isPlaying: true });
  };

  const stop = () => {
    carouselContext.setStoreState({ isPlaying: false });
  };

  return (
    <div onMouseEnter={stop} onMouseLeave={start} onFocus={stop} onBlur={start}>
      <Slider>
        {items.map((item, index) => (
          <Slide key={item.uid} index={index}>
            <a href={item?.clickUrl}>
              <img
                className="aspect-[6/1] w-full object-cover object-center"
                src={item?.bannerImage?.url}
                alt={item.name}
                title={item.name}
              />
            </a>
          </Slide>
        ))}
      </Slider>
    </div>
  );
};
