import { PROMOTION_BANNERS_DATA } from '../queries';
import { usePaginatedList } from './usePaginatedList';
import { clientNameService } from '../services';
import { RESPONSE_TYPES } from '../constants';

export const useGetPromotionBanners = (payload) => {
  const { loading, error, data, refetch } = usePaginatedList(PROMOTION_BANNERS_DATA, {
    typeData: RESPONSE_TYPES.promotionBanners,
    filter: payload?.filter,
    context: {
      clientName: clientNameService.clientName,
    },
  });

  return { loading, error, promotionBanners: data?.list, refetch };
};
